import logo from './logo.svg';
import './App.css';
import Layout from './layouts/LayoutWrapper';

import Template from './marketing/Template';
import Hero from './marketing/Hero';
import Hero2 from './marketing/Hero2';
import About from './marketing/About';
import TextContent from './marketing/TextContent';
import Pricing from './marketing/Pricing';
import Blog from './marketing/Blog';
import BlogArticle from './marketing/BlogArticle';
import QRGenerator from './components/QRGenerator';

import ToastMessageDisplay from './components/ToastMessageDisplay'
import Parallax from './components/Parallax';
import Directory from './affiliateDirectory/Directory';
import ManagementHeader from './admin/ManagementHeader';
import CRUD from './admin/CRUD';
import ListView from './admin/ListView';
import Analytics from './admin/Analytics';
import PageEditor from './admin/PageEditor';
import Login from './admin/Login';
import PasswordReset from './admin/PasswordReset';
import LinkRedirect from './components/LinkRedirect';

import FrankenCard from './frankenstein/FrankenCard'
import FrankenSocial from './frankenstein/FrankenSocial'

import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';


function App() {

  let homeDir = '';
  const [theme, setTheme] = useState({
    primary: "#141417ff",
    secondary: "#4a4f59ff",
    tertiary: "#e8f3ff",
    quarnary: "#259ef9",
    socialStyle: "noBorder"
  });


  const [messages, setMessages] = useState([]);
  const addToastMessage = (message) =>{
    let d = new Date();
    setMessages(prevState => [...prevState, {...message, id:d.getTime()}])
  }

  let qr_text = <>
                  <p>Free QR Code generator for any data you want to turn into a QR!</p>
                  <ul style={{width:'fit-content', margin:'0 auto', textAlign:'left'}}>
                    <li>Add your link or other data</li>
                    <li>Select your color, background, and QR styles</li>
                    <li>Select your code's efficiency (Higher Efficiency results in a smaller code, but Lower Efficiencies are easier for devices to scan) </li>
                    <li>Download your custom QR code to post, print, and display</li>
                  </ul>
                  <p>QR Generator will always be free, no need to sign up. Looking for dynamic QR codes that you can change on the fly and track? Sign up for our <a href="/">waitlist</a> to get access to our link shortener + dynamic QR solution.</p>
                </>

  return (
    <div className="App" style={{'--primary':theme['primary'],'--secondary':theme['secondary'],'--tertiary':theme['tertiary'],'--quarnary':theme['quarnary']}}>
      <svg style={{display:'none'}}>
        <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="1 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="4"/>
          <feOffset dx="0" dy="5" result="offsetblur"/>
          <feFlood flood-color="rgb(0, 0, 0)" result="color"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
      </svg>
      <BrowserRouter basename={''}>
        <Routes>
          <Route element={<Outlet context={addToastMessage}/>}>

            <Route element={<Template showFooter={false}/>}>
              <Route index element={<Hero2/>} />
            </Route>

            <Route element={<Template />}>
              <Route path={`${homeDir}/about`} element={<About/>} />
              <Route path={`${homeDir}/pricing`} element={<Pricing/>} />
              <Route path={`${homeDir}/qr-maker`} element={<><h1>Epicenter's QR Generator</h1><QRGenerator/>{qr_text}</>} />
              <Route path={`${homeDir}/affiliate-directory`} element={<Directory/>} />
              <Route path={`${homeDir}/privacy-policy`} element={<TextContent content='privacy-policy'/>} />
              <Route path={`${homeDir}/terms-and-conditions`} element={<TextContent content='terms-and-conditions'/>} />
              <Route path={`${homeDir}/blog`} element={<Blog/>} />
              <Route path={`${homeDir}/blog/:slug`} element={<Blog/>} />
            </Route>

            <Route path={`${homeDir}/parallax`} element={<Parallax/>} />


            <Route path={`${homeDir}/login`} element={<Login />} />
            <Route path={`${homeDir}/password_reset`} element={<PasswordReset />} />

            <Route path={`${homeDir}/admin`} element={<ManagementHeader />}>
              <Route path={`analytics`} element={<Analytics />} shouldRevalidate={true}/>
              <Route path={`page_editor/:id`} element={<PageEditor />} />
              <Route path={`page_editor`} element={<ListView showTable='pages' alternateRedirect={'/admin/page_editor/'} />} />
              <Route path={`:table/:id`} element={<CRUD />} />
              <Route path={`:table`} element={<ListView />} />
            </Route>

            <Route path={`${homeDir}/frankencard`} element={<FrankenCard />} />
            <Route path={`${homeDir}/frankensocial`} element={<FrankenSocial />} />

            {window.location.hostname === 'ectr.io' && (
              <Route path={`${homeDir}/:link_id`} element={ <LinkRedirect/> } />
            )}

            <Route path={`${homeDir}/:slug`} element={ <Layout theme={theme} updateTheme={setTheme}/> } />
          </Route>

        </Routes>
      </BrowserRouter>

      <ToastMessageDisplay messages={messages} setMessages={setMessages} />
    </div>

  );
}

export default App;
