import React from 'react';
import './FrankenCard.css'

import Card from '../cards/Card'

const FrankenCard = ({  }) => {
  let card_type = `default`;

  let title = 'Instagram';
  let text = 'Come follow my miniature making journey';
  let card_img = 'https://the-cutting-edges.com/static/uploads/-OiZtBjAsZg7S+YD.png';

  let theme = {}

  let cardTypes = ['default','glide','block','fade']
  let cardType = [cardTypes[3]]

  return (
    <div className='FrankenCard'>
      <div>Card Type</div>
      <div>All</div>
      <div>Primary</div>
      <div>Title,Text</div>
      <div>Title,Image</div>
      <div>Image,Text</div>
      <div>Title</div>
      <div>Text</div>
      <div>Image</div>
      <div>None</div>
      {cardType.map((item, i) => {
        return <>
          <div>{item}</div>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} type={'Primary'} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} title={title} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} title={title} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} className={item}/>
        </>
      })}
    </div>
  );
};

export default FrankenCard;
