import React from 'react';
import './Card.css'
import './Glide.css'
import './Block.css'
import './Fade.css'

const Card = ({ key, link_id, title, text, url, img, theme, type, onClick, className="default" }) => {
  className = className == "default" ? "card" : className;
  let classNames = `${className} ${type} mainHover ${type}`;

  if(title){
    classNames += ' hasTitle';
  }
  if(text){
    classNames += ' hasText';
  }
  if(img){
    classNames += ' hasImage';
  }

  if(![].includes(type)){
    type = 'basic';
  }

  return (
    <a data-link={link_id} href={url} target="_blank" onClick={onClick}>
      <div className={classNames}>
        <img class="image" src={img} alt={title} />
        <span class="title">{title}</span>
        <span class="text">{text}</span>
      </div>
    </a>
  );
};

export default Card;
