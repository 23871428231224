import React from 'react';
import styles from './layout1.css'
import Card from '../cards/Card';
import Button from '../forms/Button'
import ContactIcon from '../components/ContactIcon';
import SocialButton from '../components/SocialButton';

import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import { downloadContactCard } from '../utils/fileProcessing'


const Layout1 = ({ profile, links, theme, linkClick, subscribe }) => {

  //profile, links, theme,


  const socialButtons = links.map((card, index) => {
      if(links[index]['section'] == 1){
        return <SocialButton href={card['url']} link_id={card['id']} type={theme['socials_style']} fill={theme['tertiary']} onClick={linkClick}/>
      }
      return null;
    })

  let primary_set = false;
  const cards = links.map((card, index) => {
      if(card['section'] == 2){
        let first = !primary_set;
        primary_set = true;
        return <Card key={index} link_id={card['id']} theme={theme} img={card.image?.url} type={first && theme['cta']=='default_cta' ? 'Primary' : ''} onClick={linkClick} {...card} />;
      }
      return null;
    })
  if(theme['cta'] == 'subscribe_cta'){
    cards.unshift(<Card key='-1' type='Primary' onClick={subscribe} title="Subscribe" text={profile['subscribe_copy']}/>)
  }

  let profileImgSrc = 'https://via.placeholder.com/150';
  if('image' in profile){
    profileImgSrc = profile['image']['url'];
  }


  return (
    <div className="Layout1" style={{'--primary':theme['primary'],'--secondary':theme['secondary'],'--tertiary':theme['tertiary'],'--quarnary':theme['quarnary']}}>
      <div class="profile">
        <div onClick={() => {if(theme['download_contact']){downloadContactCard(profile.id, profile.display_name)}}}>
          <ContactIcon img={profileImgSrc} downloadable={theme['download_contact']}/>
        </div>
        <div class="bio">
          <span class="name">{profile['display_name']}</span>
          <span>{profile['bio']}</span>
        </div>
      </div>

      <div class="alignHorizontal social-section">
        {socialButtons}
      </div>

      <div class="link-section alignVertical">
        {cards}
      </div>

      { theme['collect_email']?
      <>
        <div style={{position:'absolute', top:'0', right:'0', background:'none', border:'none', color:'var(--tertiary)', fontWeight:'bolder', cursor:'pointer'}}>
          <Button label="Subscribe" color={theme['tertiary']} noPadding={true} onClick={subscribe}/>
        </div>
      </>
      :<></>}

    </div>
  );
};

export default Layout1;
