import './Form.css';
import styles from './ColorPicker.css';
import React, {useEffect, useState } from 'react';


const ColorPicker = ({name, label, value, onChange= (val)=>{} }) => {

  const colorPickerOnChange = (event) => {
    onChange(event);
  }
  const updateInput = (hue, saturation, lightness) =>{
    let color = hslToHex(hue, saturation, lightness);
    let input = document.querySelector('[name='+name+']');
    input.value = color;
    let ev = new Event('change');
    input.dispatchEvent(ev);
    colorPickerOnChange(ev);
    input.focus();
  }

  function hexToHSL(hex) {
    if(! hex){return {hue:0, saturation:0, lightness:0};}
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if(!result || result.length < 3){return {hue:0, saturation:0, lightness:0};}
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
    if(max == min){
      h = s = 0; // achromatic
    }else{
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch(max){
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    return {hue:h*360, saturation:s*100, lightness:l*100};
  }

  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }



  const clickWheel = (event) => {
    let curCol = hexToHSL(value);
    let bounds = event.target.getBoundingClientRect();
    let x = event.clientX - bounds.left - (bounds.width/2);
    let y = event.clientY - bounds.top - (bounds.height/2);
    let hue = 90 - Math.atan(x/y) * (180/Math.PI);
    if(y < 0){hue += 180}
    let saturation = ((x**2 + y**2) / (bounds.width/2)**2)*100;

    updateInput(hue, saturation, curCol.lightness || 50);

  }

  const clickShadeBar = event => {
    let curCol = hexToHSL(value);
    let bounds = event.target.getBoundingClientRect();
    let y = event.clientY - bounds.top;
    let lightness = 100 - (100 * y / bounds.height);

    updateInput(curCol.hue, curCol.saturation, lightness)

  }

  const [selectedHue, setSelectedHue] = useState(0);
  const [selectedSaturation, setSelectedSaturation] = useState(0);

  useEffect(() => {
    setSelectedHue(hexToHSL(value).hue);
    setSelectedSaturation(hexToHSL(value).saturation);
  }, [value]);

  return (
    <div className="ColorPicker">
      <div class="inputWrapper">
        <input name={name} value={value} onChange={colorPickerOnChange} placeholder={label}/>
        <div class="ColorPreview" style={{background:value}}></div>
        <div class="ColorDrawer">
          <div class="ColorWheel" onClick={clickWheel}></div>
          <div class="ColorShadeBar" onClick={clickShadeBar} style={{"--color":value,"--selectedHue":selectedHue,"--selectedSaturation":selectedSaturation}}></div>
        </div>
      </div>
      <label>{label}</label>
    </div>
  );
};

export default ColorPicker;
