import React from 'react';
import styles from './layout2.css';
import '../utils/animations.css';
import Card from '../cards/Card';
import Button from '../forms/Button';
import ContactIcon from '../components/ContactIcon';
import SocialButton from '../components/SocialButton';

import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import { downloadContactCard } from '../utils/fileProcessing'


const Layout2 = ({ profile, links, theme, linkClick, subscribe }) => {


  const socialButtons = links.map((card, index) => {
      if(links[index]['section'] == 1){
        return <SocialButton href={card['url']} link_id={card['id']} type={theme['socials_style']} fill={theme['tertiary']} onClick={linkClick}/>
      }
      return null;
    })

  let primary_set = false;
  const cards = links.map((card, index) => {
      if(card['section'] == 2){
        let first = !primary_set;
        primary_set = true;
        return <Card key={index} link_id={card['id']} theme={theme} img={card.image?.url} type={first && theme['cta']=='default_cta' ? 'Primary' : ''} className='AbilityCard' onClick={linkClick} {...card} />;
      }
      return null;
    })
  if(theme['cta'] == 'subscribe_cta'){
    cards.unshift(<Card key='-1' type='Primary' onClick={subscribe} title="Subscribe" text={profile['subscribe_copy']}/>)
  }

  let profileImgSrc = 'https://via.placeholder.com/150';
  if('image' in profile){
    profileImgSrc = profile['image']['url'];
  }


  return (
    <div className="Layout2 shimmer" style={{'--primary':theme['primary'],'--secondary':theme['secondary'],'--tertiary':theme['tertiary'],'--quarnary':theme['quarnary']}}>

      <div style={{position:'relative'}}>
        <img style={{objectFit:'cover', height: '1000px', maxWidth:'100%', maxHeight:'50vh', aspectRatio:'1/1', margin:'0', padding: '0', display:'block'}} src={profileImgSrc}/>

        <div style={{position:'absolute', bottom:'0', right:'0'}} class="alignHorizontal end social-section">
          {socialButtons}
        </div>
      </div>

      <div style={{margin:'0', position:'relative', height:'2em'}}>
        <div style={{position: 'absolute', width:'5%', left:'0', top:'-5px', height:'10px', background:'var(--tertiary)'}} ></div>
        <div style={{ position: 'absolute', width:'75%', right:'0', top:'-5px', height:'10px', background:'var(--tertiary)'}} ></div>
        <div style={{position: 'absolute', left:'15%', top:'0', transform:'translate(-50%, -50%)', fontWeigh:'bold', fontSize:'2em', padding:'.25em', borderRadius: '50%', border:'5px solid var(--tertiary)', aspectRatio:'1', display:'block', background:'var(--primary)', height:'1.4em'}}>10</div>
      </div>

      <div class="bio">
        <span class="name">{profile['display_name']}</span>
        <span class="description">{profile['bio']}</span>
      </div>

      <div class="link-section alignVertical" style={{margin:"1em 0 0 0"}}>
        {cards}
      </div>

      { theme['download_contact']?
      <div class="Contact" onClick={()=>downloadContactCard(profile.id, profile.display_name)} >
        Add Contact <u style={{fontWeight:'bold', fontSize:"1.2rem"}}>↓</u>
      </div>
      :<></>}

      { theme['collect_email']?
      <>
        <div style={{position:'absolute', top:'0', right:'0', background:'none', border:'none', color:'var(--tertiary)', fontWeight:'bolder', cursor:'pointer'}}>
          <Button label="Subscribe" color={theme['tertiary']} noPadding={true} onClick={subscribe}/>
        </div>
      </>
      :<></>}

    </div>
  );
};

export default Layout2;
