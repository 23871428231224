import React from 'react';
import styles from './LayoutWrapper.css'
// import ContactIcon from './ContactIcon';
// import SocialButton from './SocialButton';
import Popup from '../components/Popup'
import Subscribe from '../components/Subscribe'

import { downloadContactCard } from '../utils/fileProcessing'
import { printOne } from '../utils/dateFunctions';

import Cookies from 'js-cookie';
import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import Button from '../forms/Button'

import Layout1 from './layout1';
import Layout2 from './layout2';
import Layout3 from './layout3';
import Layout4 from './layout4';
import Layout5 from './layout5';
import Layout6 from './layout6';

import Footer from '../components/Footer'

const Layout = ({ theme, updateTheme }) => {
  let { slug } = useParams();

  let homeDir = '';

  const [links, setLinks] = useState([]);
  const [profile, setProfile] = useState({'profile':'...','bio':''})

  let membership = Cookies.get('membership');
  let referrer = document.referrer;
  let thisUrl = document.location.href;
  let campaign = new URLSearchParams(window.location.search).get("campaign");


  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let deviceType;
  if (width <= 600) {
    deviceType = 'mobile';
  } else if (width <= 1024) {
    deviceType = 'tablet';
  } else {
    deviceType = 'desktop';
  }

  // if(membership === undefined){
  //   console.log('get token');
  //   fetch(process.env.REACT_APP_API_URL + '/get_member', {
  //     headers: {
  //       'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //     })
  // }else{
  //   console.log('log event');
  // }


  function linkClick(event) {
    const link = event.target.closest("a").dataset.link;

    fetch(process.env.REACT_APP_API_URL + '/events', {
      method: 'POST',
      headers: {
        'membership': Cookies.get('membership'),
        'Authorization': Cookies.get('Authorization'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'link_id':link, 'member_id':Cookies.get('membership'), 'profile_id':profile['id'], 'variety':'outbound', 'campaign':campaign, 'referrer_url':referrer, 'device':deviceType })
    })
  }


  const fetchDataRails = () => {
    let pname = window.location.pathname.replace(homeDir, "");

    fetch(process.env.REACT_APP_API_URL + '/show_page' + pname, {
      headers: {
        'membership': Cookies.get('membership'),
        'referrer_url': referrer,
        'campaign': campaign,
        'device': deviceType,

        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setProfile({...data['profile'], subscribe_copy:data['subscribe_copy']});
        document.title = data['profile']['bio'] ? data['profile']['display_name'] + '  |  ' + data['profile']['bio'] : data['profile']['display_name']

        setLinks(data['links']);

        updateTheme({
          primary: data['primary_color'],
          secondary: data['secondary_color'],
          tertiary: data['tertiary_color'],
          quarnary: data['quarnary_color'],
          layout_type: data['layout_type'],
          socials_style: data['socials_style'],
          card_type: data['card_type'],
          download_contact: data['download_contact'],
          collect_email: data['collect_email'],
          cta: data['cta'],

          ...data['custom_attributes']
        });


        if(data.membership && data.membership != null){
          Cookies.set('membership', data.membership)
        }

      });
  }

  useEffect(() => {
    fetchDataRails();
  },[]);




  //layout_one, layout_two
  let layout;
  // the "forbidden" layout
  if(theme['layout_type'] == 'layout_six'){
    layout = <Layout6 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }
  // digital business card
  else if(theme['layout_type'] == 'layout_five'){
    layout = <Layout5 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }
  // 3d model layout
  else if(theme['layout_type'] == 'layout_four'){
    layout = <Layout4 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }
  // pokemon card
  else if(theme['layout_type'] == 'layout_three'){
    layout = <Layout3 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }
  // basic game card
  else if(theme['layout_type'] == 'layout_two'){
    layout = <Layout2 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }
  // layout with basic profile image
  else{
    layout = <Layout1 profile={profile} links={links} theme={theme} linkClick={linkClick} subscribe={()=>setSubscribePopup(true)}/>
  }



  const [subscribeVisible, setSubscribePopup] = useState(false);

  // , cursor: 'url(drake_cursor.png), url(drake_cursor.cur), auto'
  // TODO: change to prevent undefined for theme['background_burn']
  return (
    <div className={`LayoutWrapper ${theme['background_burn']}`} style={{display:'flex', flexDirection:'column', width:'100vw', minHeight:'100dvh', margin:'0'}}>



      <div class='padder' style={{flexGrow:'1'}}>
        {layout}
      </div>

      <Popup visible={subscribeVisible} setVisibility={setSubscribePopup}>
        <Subscribe profile_id={profile['id']} subscribeText={profile['subscribe_copy']} name={profile['display_name']}/>
      </Popup>


      {profile.hide_footer ? '' : <Footer footerText="Epicenters.io" link='https://epicenters.io'/>}

    </div>
  );
};

export default Layout;
